<template>

  <v-card-title style="margin-bottom: 20px;">Search for
    {{
      this.$route.query.entity === "horseforms"
          ? "Horse-Form"
          : capitalize(this.$route.query.entity)
    }}
  </v-card-title>
  
  <div class="row">
    <div class="v-col-3">
      <span class="p-float-label">
            <InputText id="searchField" type="text" style="width:99%;" v-model="searchTerm"
                       @keydown.enter="searchEntity(searchTerm)"/>
            <label for="searchField">Search terms</label>
      </span>
    </div>
    <div class="v-col-1">
      <v-btn
          :disabled="isSearchDisabled"
          color="primary"
          dark
          class="mb-2"
          @click="searchEntity(searchTerm)">
        Search
      </v-btn>
    </div>
    <div v-if="this.$route.query.entity == 'horses'" class="v-col-5">
      <div class="row">
        <div class="v-col">
          <RadioButton v-model="search_type" inputId="horseSch" name="search_type" value="horse" />
          <label for="horseSch" class="ml-2">Horse</label>
        </div>
        <div class="v-col">
            <RadioButton v-model="search_type" inputId="damSch" name="search_type" value="dam" />
            <label for="damSch" class="ml-2">Dam</label>
        </div>
        <div class="v-col">
            <RadioButton v-model="search_type" inputId="sireSch" name="search_type" value="sire" />
            <label for="sireSch" class="ml-2">Sire</label>
        </div>
      </div>
    </div>
  </div>
  <v-progress-linear
      v-model="progressBarValue"
      color="primary"
      indeterminate
      :active="showProgressBar"
  ></v-progress-linear>
</template>

<script>
import {API} from "aws-amplify";
import InputText from "primevue/inputtext";
import moment from "moment";
import RadioButton from 'primevue/radiobutton';


export default {
  components: {
    InputText,
    RadioButton
  },
  data: () => ({
    searchTerm: "",
    showProgressBar: false,
    progressBarValue: 0,
    results: [],
    search_type: 'horse'
  }),
  emits: ['searchResults'],
  watch: {
    searchResults(searchTerm) {
      console.log("Emitting results (watch)");
      this.$emit("searchResults", searchTerm);
    },
  },
  methods: {
    async searchEntity(searchTerm) {
      if (searchTerm == "") {
        alert("Please enter a search term.")
        return [];
      }

      this.showProgressBar = true;
      this.results = [];
      switch (this.$route.query.entity) {
        case "horses": {
          var horse_params = 's='
          switch (this.search_type){
            case "dam": {
              horse_params = 'dam=true&s='
              break;
            }
            case "sire": {
              horse_params = 'sire=true&s='
              break;
            }
            default:
              break;
          }
          await API.get(
              "MrCenApiGateway",
              `/admin/search/horse?${horse_params}${searchTerm}`
          ).then((response) => {
            this.results = response;
            this.$emit("searchResults", this.results);
            this.showProgressBar = false;
          });
          break;
        }
        case "horseforms": {
          await API.get(
              "MrCenApiGateway",
              `/admin/search/horse-form?s=${searchTerm}`
          ).then(async (response) => {
            this.results = response;
            let flatHf = [];
            this.results.forEach((r) => {
              if (r.horse_id !== undefined && r.form !== undefined) {
                r.form.forEach(f => {
                  f.horse_id = r.horse_id;
                  f.horse_name = r.horse_name;
                  f.form_count = r.form.length;
                  f.distance_metres = f.distance['@_metres'];
                  f.meeting_date = moment(f.meeting_date).format("YYYY-MM-DD");
                  if (f.track !== undefined)
                    f.track_name = f.track['@_name'];
                  if (f.classes !== undefined)
                    f.class_name = f.classes.class;
                  flatHf.push(f);
                });

              }
              // if (r.horse_name !== undefined)
              //   r.form.horse_name = r.horse_name;
            });
            console.log("HF Results");
            console.log(flatHf);

            this.$emit("searchResults", flatHf);
            this.showProgressBar = false;
          });
          break;
        }
        case "trainers": {
          await API.get(
              "MrCenApiGateway",
              `/admin/search/trainer?s=${searchTerm}`
          ).then(async (response) => {
            this.results = response;
            this.$emit("searchResults", this.results);
            this.showProgressBar = false;
          });
          break;
        }
        case "jockeys": {
          await API.get(
              "MrCenApiGateway",
              `/admin/search/jockey?s=${searchTerm}`
          ).then(async (response) => {
            response.forEach((item) => {
              item.JOC_STATE_REGISTERED = this.getState(item.JOC_STATE_REGISTERED)
              item.JOC_JOCKEY_APPRENTICE_IND === "Y" ? item.JOC_JOCKEY_APPRENTICE_IND = "Yes" : item.JOC_JOCKEY_APPRENTICE_IND = "No";
            });
            this.results = response;
            this.$emit("searchResults", this.results);
            this.showProgressBar = false;
          });
          break;
        }
        case "tracks": {
          await API.get(
              "MrCenApiGateway",
              `/admin/search/track?s=${searchTerm}`
          ).then(async (response) => {
            response.forEach((item) => {
              item.TRK_STATE_OF_TRACK = this.getState(item.TRK_STATE_OF_TRACK)
            });
            this.results = response;
            this.$emit("searchResults", this.results);
            this.showProgressBar = false;
          });
          break;
        }
        case "clubs": {
          await API.get(
              "MrCenApiGateway",
              `/admin/search/club?s=${searchTerm}`
          ).then(async (response) => {
            response.forEach((item) => {
              item.CLB_STATE = this.getState(item.CLB_STATE)
            });
            this.results = response;
            this.$emit("searchResults", this.results);
            this.showProgressBar = false;
          });
          break;
        }
        default:
          break;
      }
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },
    getState(stateId) {
      switch (stateId) {
        case 0:
          return "";
        case 1:
          return "ACT";
        case 2:
          return "NSW";
        case 3:
          return "VIC";
        case 4:
          return "QLD";
        case 5:
          return "SA";
        case 6:
          return "WA";
        case 7:
          return "TAS";
        case 8:
          return "NT";
        case 9:
          return "NZ";
        case 10:
          return "HK";
        case 11:
          return "SGP";
        case 99:
          return "Aus";
      }
    },
    capitalize(str) {
      if (str !== undefined) return str.charAt(0).toUpperCase() + str.slice(1);
    },


  },
  computed: {
    isSearchDisabled() {
      return this.searchTerm.trim().length === 0;
    },
  },
  mounted() {

  },
};
</script>

<style>
</style>
